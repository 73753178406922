import moment from 'moment'
import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'
import { favicon } from '@/mixins/utils'
import { publicIpv4 } from 'public-ip'
import { tipoImagem, blobToDataURL, base64ToBlob } from '@/mixins/utils'

function getLocalStorageItem (key, parseJson = false) {
  const item = myLocalStorage.getItem(key);
  return parseJson && item ? JSON.parse(item) : item;
}

export default {
  namespaced: true,
  state: {
    status: '',
    valid: getLocalStorageItem('valid'),
    loginUrl: getLocalStorageItem('loginUrl'),
    empresa: getLocalStorageItem('empresa', true) || 0,
    setup: getLocalStorageItem('setup', true) || {},
    user: getLocalStorageItem('user', true) || {},
    logo: getLocalStorageItem('logo') || '',
    favicon: getLocalStorageItem('favicon') || '',
    portal: getLocalStorageItem('portal') || false,
    versao: getLocalStorageItem('versao', true) || {}
  },
  mutations: {
    updateField,
    auth_request (state) {
      state.status = 'carregando...'
    },
    auth_success (state, user) {
      state.status = 'autenticado'
      state.user = user
      state.valid = moment().format()
      state.loginUrl = window.location.pathname
      myLocalStorage.setItem('user', JSON.stringify(user))
      myLocalStorage.setItem('token', user.ds_token)
      myLocalStorage.setItem('valid', state.valid)
      myLocalStorage.setItem('logo', state.logo)
      myLocalStorage.setItem('favicon', state.favicon)
      myLocalStorage.setItem('loginUrl', state.loginUrl)
    },
    auth_error (state, msg) {
      state.status = msg || 'acesso negado'
      state.user = {}
      myLocalStorage.removeItem('token')
      myLocalStorage.removeItem('user')
      myLocalStorage.removeItem('valid')
    },
    auth_exit (state) {
      state.status = ''
      state.user = {}
      localStorage.clear()
      myLocalStorage.setItem('versao', JSON.stringify(state.versao))
    },
    auth_token (state, tok) {
      state.user.ds_token = tok
      myLocalStorage.setItem('token', tok)
    },
    auth_portal (state) {
      state.portal = true
      myLocalStorage.setItem('portal', true)
    },
    auth_valid (state, val) {
      state.valid = val
      myLocalStorage.setItem('valid', val)
    },
    SET_LOGO (state, payload) {
      state.logo = payload.imagem
      state.favicon = payload.favicon
      favicon(state.favicon)
    },
    SET_EMPRESA (state, payload) {
      state.empresa = payload
      if (payload) myLocalStorage.setItem('empresa', payload)
    },
    SET_VERSAO (state, val) {
      state.versao = {
        vue: '3078.1',
        cgi: val.nr_clinux,
        banco: val.nr_banco
      }
      myLocalStorage.setItem('versao', JSON.stringify(state.versao))
    },
    SET_SETUP (state, val) {
      state.setup = val
      myLocalStorage.setItem('setup', JSON.stringify(val))
    }
  },
  actions: {
    async loginFuncionario ({ commit }, user) {
      try {
        commit('auth_request')
        const response = await api().post('se1/doFuncionarioLogin', user)
        commit('auth_success', response.data[0])
        return response
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    logout ({ commit }) {
      commit('auth_exit')
    },
    async portalSetup ({ commit }, payload) {
      commit('SET_EMPRESA', payload)
      try {
        const response = await api().post('se1/doPortalSetup', { cd_empresa: payload })
        commit('SET_SETUP', response.data[0])
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async exibeLogo ({ commit, state }) {
      if (!state.logo) {
        try {
          const response = await api().post('se1/doAgendaLogo', { cd_empresa: state.empresa })
          if (response.data?.length) {
            let imagem = base64ToBlob(response.data[0].bb_logo)
            //let favicon = base64ToBlob(response.data[0].bb_icon)
            let favicon = response.data[0].bb_icon
            imagem = imagem ? await blobToDataURL(imagem) : null
            // favicon = favicon ? await blobToDataURL(favicon) : null
            if (favicon) favicon = `data:${tipoImagem(favicon)};base64,${favicon}`
            commit('SET_LOGO', { imagem, favicon })
            commit('SET_VERSAO', response.data[0])
          }
        } catch (e) {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        }
      }
    },
    async loginMedico ({ commit }, user) {
      try {
        commit('auth_request')
        user.ip = await publicIpv4()
        const response = await api().post('se1/doMedicoLogin', user)
        commit('auth_success', response.data[0])
        return response
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async loginPaciente ({ commit }, user) {
      try {
        commit('auth_request')
        user.ip = await publicIpv4()
        const response = await api().post('se1/doPacienteLogin', user)
        commit('auth_success', response.data[0])
        if (user.portal) {
          commit('auth_portal')
        }
        return response
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },

    async loginResultados ({ commit }, user) {
      try {
        commit('auth_request')
        user.ip = await publicIpv4()
        const response = await api().post('se1/doAgendaLogin', user)
        const paciente = response.data[0]
        paciente.sn_resultado = true
        paciente.nr_controle = user.nr_controle
        commit('auth_success', paciente)
        if (user.portal) {
          commit('auth_portal')
        }
        return response
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },

    async loginAnamnese ({ commit, dispatch }, user) {
      try {
        const response = await dispatch('loginFuncionario', user)
        const funcionario = response.data[0]?.cd_funcionario ? response.data[0] : null
        if (funcionario) {
          const responseEntrevista = await dispatch('validarPermissoes', {
            cd_funcionario: funcionario.cd_funcionario,
            ds_form: 'GER_FUNCIONARIO_EXE_ENTREVISTA'
          })
          const responseQuestionario = await dispatch('validarPermissoes', {
            cd_funcionario: funcionario.cd_funcionario,
            ds_form: 'GER_FUNCIONARIO_EXE_QUESTIONARIO'
          })
          funcionario.permissoes = {}
          funcionario.permissoes.entrevista = !!responseEntrevista.data[0]?.cd_permissao
          funcionario.permissoes.editar = !!responseQuestionario.data[0]?.cd_permissao
          if (!funcionario.permissoes.editar && !funcionario.permissoes.entrevista) {
            throw new Error('Seu usuário não possui permissão de acesso !')
          }
          commit('auth_success', funcionario)
          return funcionario
        } else {
          commit('auth_error', 'Usuário ou senha inválida !')
        }
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },

    async loginAnamneseExterno ({ commit }, user) {
      try {
        commit('auth_request')
        const response = await api().post('se1/doAnamneseLogin', user)
        const usuario = response.data[0]
        usuario.permissoes = { editar: false, entrevista: true }
        commit('auth_success', usuario)
        return usuario
      } catch (e) {
        commit('auth_error', e)
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    validarPermissoes ({ commit }, payload) {
      return api().post('se1/doFuncionarioAcesso', {
        cd_funcionario: payload.cd_funcionario,
        ds_form: payload.ds_form
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async loginOtrs ({ commit }, user) {
      try {
        const response = await api().post('se1/dotOtrsLogin', user)
        response.data[0].ds_email = user.id
        commit('auth_success', response.data[0])
        return response
      } catch (e) {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    doFuncionarioPassword ({ commit }, payload) {
      return api().post('se1/doFuncionarioPassword', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  getters: {
    getField,
    isMedico: state => !!state.user.cd_medico,
    isPaciente: state => !!state.user.cd_paciente,
    isCliente: state => !!state.user.cd_cliente,
    isLoggedIn: state => !!state.user.ds_token || !!state.user.cd_cliente || !!state.user.cd_fornecedor,
    authStatus: state => state.status,
    token: state => state.user.ds_token,
    currentUser: state => state.user,
    setup: state => state.setup
  }
}
